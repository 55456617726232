import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/Chooser.tsx";
import '../styles/index.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Container = makeShortcode("Container");
const Columns = makeShortcode("Columns");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Heading = makeShortcode("Heading");
const Level = makeShortcode("Level");
const LevelSide = makeShortcode("LevelSide");
const LevelItem = makeShortcode("LevelItem");
const Button = makeShortcode("Button");
const FormContact = makeShortcode("FormContact");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section size='medium' style={{
      backgroundColor: '#f8fff8',
      backgroundImage: 'url(https://images.unsplash.com/photo-1558871585-4c3574a1b7cd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2251&q=80)',
      backgroundSize: 'cover',
      backgroundPosition: '0 70%'
    }} mdxType="Section">
  <Container className='presentation' mdxType="Container">
    <Columns mdxType="Columns">
      <Column size={6} mdxType="Column">
        <Box mdxType="Box">
          <br />
          <Heading renderAs='h2' size={3} mdxType="Heading">
            On s'appelle ?
          </Heading>
          <Level mdxType="Level">
            <LevelSide align='left' mdxType="LevelSide">
              <LevelItem mdxType="LevelItem">Nos équipes sont joignables au</LevelItem>
              <LevelItem mdxType="LevelItem">
                <Button renderAs='a' href='tel:+33184203365' mdxType="Button">
                  01 84 20 33 65
                </Button>
              </LevelItem>
            </LevelSide>
          </Level>
          <br />
        </Box>
      </Column>
      <Column size={6} mdxType="Column">
        <Box mdxType="Box">
          <br />
          <Heading renderAs='h2' size={3} mdxType="Heading">
            On s'écrit ?
          </Heading>
          <Level mdxType="Level">
            <LevelSide align='left' mdxType="LevelSide">
              <LevelItem mdxType="LevelItem">Contactez-nous par email</LevelItem>
              <LevelItem mdxType="LevelItem">
                <Button renderAs='a' href='mailto:contact@enia.green?subject=Contact%20depuis%20le%20site&body=Bonjour,' mdxType="Button">
                  contact@enia.green
                </Button>
              </LevelItem>
            </LevelSide>
          </Level>
          <br />
        </Box>
      </Column>
    </Columns>
  </Container>
    </Section>
    <Section size='medium' backgroundColor='light' mdxType="Section">
  <FormContact mdxType="FormContact" />
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      